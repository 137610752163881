import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <Switch>
          <Route exact path="/" component={Pages.Home} />
          <Route exact path="/profil" component={Pages.Profil} />
          <Route exact path="/epikoinonia" component={Pages.Contact} />
          <Route exact path="/results/:word" component={Pages.Results} />
          <Route
            exact
            path="/diplomata-ipiresies/diplomata-mihanis"
            component={Pages.MotorDiplomas}
          />
          <Route
            exact
            path="/diplomata-ipiresies/diplomata-autokinitou"
            component={Pages.CarDiploma}
          />
          <Route exact path="/sekam" component={Pages.Sekam} />
          <Route
            exact
            path="/diplomata-ipiresies/epaggelmatika-diplomata-pei"
            component={Pages.ProfessionalDiplomas}
          />
          <Route
            exact
            path="/diplomata-ipiresies/eidiki-adeia-odigisis-taxi"
            component={Pages.TaxiLicense}
          />
          <Route
            exact
            path="/diplomata-ipiresies/anatheoriseis"
            component={Pages.Reviews}
          />
          <Route
            exact
            path="/diplomata-ipiresies/antikatastasi-metatropi"
            component={Pages.ReplacementConversation}
          />
          <Route
            exact
            path="/diplomata-ipiresies/metavivaseis"
            component={Pages.Transfers}
          />
          <Route
            exact
            path="/diplomata-ipiresies/exoplismos-sxolon-odigon"
            component={Pages.DrivingEquipment}
          />
          <Route
            exact
            path="/ipops-ekpaidevtes-sholon-odigon"
            component={Pages.Trainers}
          />
          <Route exact path="/blog" component={Pages.Blog} />
          <Route
            exact
            path="/i-ekpaideysi-ginetai-me-ta-dika-mas-sygxrona-kai-eidika-diamorfomena-ohimata"
            component={Pages.Article1}
          />
          <Route
            exact
            path="/na-ekdothei-o-kok-stin-elliniki-noimatiki-glossa"
            component={Pages.Article2}
          />
          <Route
            exact
            path="/oi-ekpaideytes-mas-einai-aristouhoi-tis-sholis-mas"
            component={Pages.Article3}
          />
          <Route exact path="/online-test-drive" component={Pages.TestDrive} />
          <Route component={Pages.NotFound} />
        </Switch>
      </Router>
    )
  );
};

export default ExportDefault;
