import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/contact";
import Services from "../../Services/Services";

export default class Contact extends Page {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setWindowTitle("ΠΑΠΑΓΙΑΝΝΙΔΗΣ Ο. Ε. - ΣΧΟΛΕΣ ΟΔΗΓΩΝ | ΕΠΙΚΟΙΝΩΝΙΑ");

    this.setData({
      "default.page": 6,
    });

    this.addScripts({
      "jquery.min": "assets/js/jquery.min",
      "bootstrap.bundle.min": "assets/js/bootstrap.bundle.min",
      "jquery.hoverIntent.min": "assets/js/jquery.hoverIntent.min",
      "jquery.waypoints.min": "assets/js/jquery.waypoints.min",
      "superfish.min": "assets/js/superfish.min",
      "owl.carousel.min": "assets/js/owl.carousel.min",
      "jquery.plugin.min": "assets/js/jquery.plugin.min",
      "jquery.magnific-popup.min": "assets/js/jquery.magnific-popup.min",
      "jquery.countdown.min": "assets/js/jquery.countdown.min",
      main: "assets/js/main",
      "demo-2": "assets/js/demos/demo-2",
    });

    // const uluru = { lat: 38.0675964, lng: 23.7551095 };

    // this.map = new window.google.maps.Map(
    //   document.getElementById("custom_map"),
    //   {
    //     zoom: 16,
    //     center: uluru,
    //   }
    // );

    // var marker = new window.google.maps.Marker({
    //   position: uluru,
    //   map: this.map,
    // });

    // window.google.maps.event.addListener(marker, "click", function () {
    //   this.map.panTo(this.getPosition());
    //   this.map.setZoom(19);
    // });

    const uluru_list = [
      { lat: 38.0198724608737, lng: 23.801917563931923 },
      { lat: 38.025095550173795, lng: 23.83662541671745 },
      { lat: 37.99996846465525, lng: 23.787539935926016 },
      { lat: 37.99061720464297, lng: 23.76102416225618 },
      { lat: 37.98669480571623, lng: 23.764616005511023 },
      //{ lat: 37.959231405262045, lng: 23.856616697672568 },
      //{ lat: 37.96630007647749, lng: 23.751895151912308 },
    ];

    this.map = new window.google.maps.Map(
      document.getElementById("custom_map"),
      {
        zoom: 12,
        center: { lat: 38.00225817231955, lng: 23.804364321493555 },
      }
    );
    uluru_list.forEach((uluru) => {
      var marker = new window.google.maps.Marker({
        position: uluru,
        map: this.map,
      });
      window.google.maps.event.addListener(marker, "click", function () {
        this.map.panTo(this.getPosition());
        this.map.setZoom(21);
      });
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });

    this.setSeoData();
  }

  async setSeoData() {
    const account = this.getHelpers("env").getDefaultAccount();

    const translations = await this.getHelpers("translate").scope({
      account: account,
      app: "content",
      entity: "item",
      entity_slug: "seo",
      scope: "default",
    });

    const description = translations.find(
      (translation) => translation.slug === "default-description"
    ) || { translation: "" };

    const keywords = translations.find(
      (translation) => translation.slug === "default-keywords"
    ) || { translation: "" };

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description.translation);

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", keywords.translation);
  }

  sendForm(e) {
    e.preventDefault();

    const message = {};

    message["name"] = document.getElementById("cname").value;
    message["email"] = document.getElementById("cemail").value;
    message["phone"] = document.getElementById("cphone").value;
    message["subject"] = document.getElementById("csubject").value;
    message["message"] = document.getElementById("cmessage").value;

    Services.get("hook").then(async ([Hook]) => {
      const response = await Hook.post("contact-us", message);

      if (response.isValid()) {
        document.getElementById("cname").value = "";
        document.getElementById("cemail").value = "";
        document.getElementById("cphone").value = "";
        document.getElementById("csubject").value = "";
        document.getElementById("cmessage").value = "";
      }
    });

    // Services.get("content").then(([content]) => {
    //   content.contact(message).then(() => {
    //     document.getElementById("cname").value = "";
    //     document.getElementById("cemail").value = "";
    //     document.getElementById("cphone").value = "";
    //     document.getElementById("csubject").value = "";
    //     document.getElementById("cmessage").value = "";
    //   });
    // });

    // if (!error.fields.message) {
    //   Services.get("order", "test").then(([orderService]) => {
    //     orderService
    //       .prepare({ address, business, invoice })
    //       .then(() => {
    //         return orderService.place();
    //       })
    //       .then(() => {
    //         this.redirect("/complete");
    //       })
    //       .catch((err) => {
    //         console.log("error", err);
    //       });
    //   });
    // }
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
