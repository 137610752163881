import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6">
              <div className="widget widget-cats mb-3">
                <h3 className="widget-title">
                  <b>
                    Σχολή Επαγγελματικής Κατάρτισης Μεταφορέων (ΣΕΚΑΜ) –
                    Επαγγελματική Κατάρτιση στις Οδικές Μεταφορές
                  </b>
                </h3>
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Στη σχολή μας, παρέχουμε ολοκληρωμένη εκπαίδευση και
                  προετοιμασία για την απόκτηση του Πιστοποιητικού
                  Επαγγελματικής Επάρκειας (Π.Ε.Ε.) και της Άδειας Οδικού
                  Μεταφορέα. Είμαστε αναγνωρισμένη ΣΕΚΑΜ, εξειδικευμένη στη
                  διαμόρφωση επαγγελματιών στον τομέα των μεταφορών,
                  προσφέροντας υψηλού επιπέδου εκπαίδευση και καθοδήγηση.
                </p>
              </div>
              <div className="widget widget-cats mb-3">
                <h3 className="widget-title">
                  <b>
                    Τι είναι το Πιστοποιητικό Επαγγελματικής Επάρκειας (Π.Ε.Ε.);
                  </b>
                </h3>
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Το Πιστοποιητικό Επαγγελματικής Επάρκειας (Π.Ε.Ε.) αποτελεί
                  απαραίτητο προσόν για όσους επιθυμούν να δραστηριοποιηθούν ως
                  οδικοί μεταφορείς εμπορευμάτων ή επιβατών εντός της Ευρωπαϊκής
                  Ένωσης. Η απόκτησή του εξασφαλίζει ότι ο επαγγελματίας
                  διαθέτει τις απαραίτητες γνώσεις και δεξιότητες για τη σωστή
                  και ασφαλή διαχείριση μιας μεταφορικής εταιρείας.
                </p>
              </div>
              <div className="widget widget-cats mb-3">
                <h3 className="widget-title">
                  <b>Τι είναι η Άδεια Οδικού Μεταφορέα;</b>
                </h3>
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Η Άδεια Οδικού Μεταφορέα είναι η επίσημη άδεια που επιτρέπει
                  τη νόμιμη άσκηση του επαγγέλματος του οδικού μεταφορέα
                  εμπορευμάτων ή επιβατών και την κατοχή οχήματος δημοσίας
                  χρήσης. Για να εκδοθεί, απαιτείται η κατοχή Πιστοποιητικού
                  Επαγγελματικής Επάρκειας, καθώς και η κάλυψη συγκεκριμένων
                  προϋποθέσεων οικονομικής και επαγγελματικής επάρκειας.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>Ποιοι χρειάζονται την Άδεια Οδικού Μεταφορέα;</b>
                </h3>
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Η άδεια είναι υποχρεωτική για:
                </p>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Ιδιοκτήτες οχημάτων δημοσίας χρήσης μεταφοράς εμπορευμάτων ή
                    επιβατών
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Επαγγελματίες που επιθυμούν να ιδρύσουν ή να διαχειρίζονται
                    μια μεταφορική εταιρεία
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φυσικά ή νομικά πρόσωπα που ασκούν δραστηριότητες διεθνών ή
                    εθνικών οδικών μεταφορών
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>
                    Δικαιολογητικά για την απόκτηση της Άδειας Οδικού Μεταφορέα
                  </b>
                </h3>
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Για την έκδοση της άδειας απαιτούνται:
                </p>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Πιστοποιητικό Επαγγελματικής Επάρκειας (Π.Ε.Ε.)
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αποδεικτικά οικονομικής επάρκειας, όπως τραπεζικές εγγυήσεις
                    ή οικονομικά στοιχεία της επιχείρησης
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Πιστοποιητικό ποινικού μητρώου
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Φορολογική και ασφαλιστική ενημερότητα
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Αίτηση προς την αρμόδια υπηρεσία μεταφορών (παρέχεται από
                    την υπηρεσία)
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <h3 className="widget-title">
                  <b>Γιατί να επιλέξετε τη σχολή μας;</b>
                </h3>
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Στη σχολή μας, διασφαλίζουμε:
                </p>
                <ul>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Σύγχρονες μεθόδους διδασκαλίας
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Έμπειρους εκπαιδευτές με πολυετή εμπειρία στον κλάδο
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#f32039",
                        fontSize: "10px",
                      }}
                    ></i>
                    Υποστήριξη σε κάθε στάδιο της διαδικασίας, από την
                    εκπαίδευση έως την έκδοση της άδειας
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <p
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Αν ενδιαφέρεστε να γίνετε επαγγελματίας οδικός μεταφορέας,
                  επικοινωνήστε μαζί μας για περισσότερες πληροφορίες!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
