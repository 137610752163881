import Home from "./Page/Home";
import Profil from "./Page/Profil";
import Contact from "./Page/Contact";
import Results from "./Page/Results";
import NotFound from "./Page/NotFound";
import MotorDiplomas from "./Page/MotorDiplomas";
import CarDiploma from "./Page/CarDiploma";
import Sekam from "./Page/Sekam";
import ProfessionalDiplomas from "./Page/ProfessionalDiplomas";
import ReplacementConversation from "./Page/ReplacementConversation";
import Reviews from "./Page/Reviews";
import TaxiLicense from "./Page/TaxiLicense";
import DrivingEquipment from "./Page/DrivingEquipment";
import Trainers from "./Page/Trainers";
import Transfers from "./Page/Transfers";
import Blog from "./Page/Blog";
import TestDrive from "./Page/TestDrive";
import Article1 from "./Page/Article1";
import Article2 from "./Page/Article2";
import Article3 from "./Page/Article3";

const Module = {
  Home,
  Profil,
  Contact,
  Results,
  NotFound,
  MotorDiplomas,
  CarDiploma,
  Sekam,
  ProfessionalDiplomas,
  ReplacementConversation,
  Reviews,
  TaxiLicense,
  DrivingEquipment,
  Trainers,
  Transfers,
  Blog,
  TestDrive,
  Article1,
  Article2,
  Article3,
};

export default Module;
